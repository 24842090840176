
export default {
  data: () => {
    return {
      isActive: false,
      isOverlay: false,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    };
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive;
      this.isOverlay = !this.isOverlay;
    },
    getTopValue(index) {
      if (this.windowWidth >= 993 && this.windowWidth <= 1200) {
        return index === 0 ? '35.5%' : '38%' && index === 1 ? '47%' : '47.5%' && index === 2 ? '58.5%' : '58%';
      } else if (this.windowWidth >= 1201 && this.windowWidth <= 1440) {
        return index === 0 ? '40%' : '38%' && index === 1 ? '50%' : '47.5%' && index === 2 ? '60%' : '58%';
      } else if (this.windowWidth >= 1441 && this.windowWidth <= 1600) {
        return `${40 + index * 8.5}%`;
      } else {
        return `${40 + index * 6.5}%`;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
