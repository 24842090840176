import { render, staticRenderFns } from "./section156.vue?vue&type=template&id=5c5a7abc&scoped=true"
import script from "./section156.vue?vue&type=script&lang=js"
export * from "./section156.vue?vue&type=script&lang=js"
import style0 from "./section156.vue?vue&type=style&index=0&id=5c5a7abc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5a7abc",
  null
  
)

export default component.exports