
export default {
  data: () => {
    return {};
  },
  mounted() {},
  methods: {
    openPopup() {
      this.$nuxt.$emit("open-popup-form", "open");
    },
  },
};
