
export default {
    data() {
        return {
            isPlaying: {},
            pipActive: {},
            currentPIPIndex: null,
            isPIPPlaying: false,
            isPipPlayPause: false,
            duration: 0,
            currentTime: 0,
            volume: 1,
            waveforms: {},
            podCastSwiper: null,
            isScrollActive: false,
            scrollTimeout: null,
        };
    },
    computed: {
        currentPodCast() {
            if (this.currentPIPIndex !== null) {
                return this.$attrs.podcost_data[this.currentPIPIndex];
            }
            return {};
        },
        pipProgress() {
            if (this.duration > 0) {
                return (this.currentTime / this.duration) * 100;
            }
            return 0;
        }
    },
    watch: {
        currentPIPIndex(newIndex) {
            // Ensure that pipActive is true and the PIP player is rendered
            if (newIndex !== null && this.pipActive[newIndex]) {
                this.$nextTick(() => {
                    const pipPlayer = document.querySelector('.pip-player');
                    if (pipPlayer) {
                        this.initDrag(pipPlayer);
                    }
                });
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (process.client) {
                this.initSwiper();
                this.initStates();
            }
        });
    },
    methods: {
        initStates() {
            this.$attrs.podcost_data.forEach((_, index) => {
                this.$set(this.isPlaying, index, false); // Initialize play state
                this.$set(this.pipActive, index, false); // Initialize PIP state
            });
        },
        initSwiper() {
            this.PodCastSwiper = new Swiper(".PodCastSwiper", {
                slidesPerView: 1,
                spaceBetween: 30,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                on: {
                    slideChange: () => {
                        const prevIndex = this.PodCastSwiper.previousIndex;
                        const currentIndex = this.PodCastSwiper.activeIndex;

                        if (this.waveforms[prevIndex]) {
                            this.waveforms[prevIndex].pause();
                        }

                        this.$set(this.isPlaying, prevIndex, false);
                        this.currentTime = 0;

                        // Reinitialize waveform for the active slide
                        this.initWaveform(currentIndex);
                    }
                }
            });
            this.initWaveform(0); // Initialize for the first slide
        },
        initWaveform(index) {
            const podCastData = this.$attrs.podcost_data[index];

            // Check if audio_file exists
            if (!podCastData || !podCastData.audio_file) {
                return;
            }

            if (this.waveforms[index]) {
                this.waveforms[index].destroy();
            }

            // Initialize a new Wavesurfer instance
            this.waveforms[index] = window.WaveSurfer.create({
                container: `#waveform_${index}`,
                waveColor: '#DBDBDB',
                progressColor: '#5997D0',
                height: 80,
                barWidth: 2.5,
                barGap: 1.5,
                barHeight: null,
                barAlign: 'start',
                barRadius: 16,
                cursorWidth: 0,
                responsive: true,
                normalize: false,
                autoCenter: false,
            });

            this.waveforms[index].load(podCastData.audio_file);

            this.waveforms[index].on('ready', () => {
                this.duration = this.waveforms[index].getDuration();
            });

            this.waveforms[index].on('play', () => {
                this.$set(this.isPlaying, index, true);
                this.toggleSwiperButtons(true);
            });

            this.waveforms[index].on('pause', () => {
                this.$set(this.isPlaying, index, false);
                this.toggleSwiperButtons(false);
            });

            this.waveforms[index].on('audioprocess', () => {
                this.currentTime = this.waveforms[index].getCurrentTime();
            });
        },
        enterPIP(index) {
            this.waveforms[index].pause();
            this.currentPIPIndex = index;
            this.$set(this.pipActive, this.currentPIPIndex, true);
            this.isPIPPlaying = true;
            this.isPipPlayPause = true;

            setTimeout(() => {
                if (this.pipActive[this.currentPIPIndex] && this.waveforms[this.currentPIPIndex]) {
                    this.waveforms[this.currentPIPIndex].play();
                    this.toggleSwiperButtons(true);
                }
            }, 500);

            this.isScrollActive = false;
            this.scrollTimeout = setTimeout(() => {
                this.isScrollActive = true;
            }, 5000);
        },
        exitPIP() {
            this.$set(this.pipActive, this.currentPIPIndex, false); // Reset PIP state
            this.isPIPPlaying = false;
            this.$set(this.isPlaying, this.currentPIPIndex, false); // Restore play state

            this.isScrollActive = false;
            clearTimeout(this.scrollTimeout);

            if (this.currentPIPIndex !== null && this.waveforms[this.currentPIPIndex]) {
                this.waveforms[this.currentPIPIndex].pause();
            }

            this.currentPIPIndex = null;
            this.toggleSwiperButtons(false);
        },
        togglePlay(index, type) {
            if (this.waveforms[index]) {
                this.waveforms[index].playPause();

                if (type === 'main') {
                    this.$set(this.isPlaying, index, !this.isPlaying[index]);
                    this.toggleSwiperButtons(true);
                }
            }
        },
        pipTogglePlay(index, type) {
            this.currentPIPIndex = index;
            if (this.waveforms[this.currentPIPIndex]) {
                this.waveforms[this.currentPIPIndex].playPause();
                if (type === 'pip') {
                    this.isPipPlayPause = !this.isPipPlayPause;
                }
            }
        },
        rewind(seconds, index) {
            const time = Math.max(this.waveforms[index].getCurrentTime() - seconds, 0);
            this.waveforms[index].seekTo(time / this.duration);
        },
        forward(seconds, index) {
            const time = Math.min(this.waveforms[index].getCurrentTime() + seconds, this.duration);
            this.waveforms[index].seekTo(time / this.duration);
        },
        changeVolume(index) {
            this.waveforms[index].setVolume(this.volume);
        },
        formatTime(seconds, index) {
            if (this.waveforms[index]) {
                const minutes = Math.floor(seconds / 60);
                const secs = Math.floor(seconds % 60);
                return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
            }
            if (this.waveforms[this.currentPIPIndex]) {
                const minutes = Math.floor(seconds / 60);
                const secs = Math.floor(seconds % 60);
                return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
            }
        },
        toggleSwiperButtons(disable) {
            const nextButton = document.querySelector('.swiper-button-next');
            const prevButton = document.querySelector('.swiper-button-prev');

            if (disable) {
                nextButton.classList.add('swiper-button-disabled');
                prevButton.classList.add('swiper-button-disabled');
            } else {
                nextButton.classList.remove('swiper-button-disabled');
                prevButton.classList.remove('swiper-button-disabled');
            }
        },

        initDrag(pipPlayer) {
            let isDragging = false;
            let offsetX = 0;
            let offsetY = 0;

            // Change cursor style on mouse down
            pipPlayer.addEventListener('mousedown', (e) => {
                isDragging = true;
                offsetX = e.clientX - pipPlayer.getBoundingClientRect().left;
                offsetY = e.clientY - pipPlayer.getBoundingClientRect().top;
                pipPlayer.style.cursor = 'grabbing'; // Change cursor style

                // Add event listener for mousemove on document
                document.addEventListener('mousemove', onMouseMove);
            });

            // On mouse up, stop dragging
            document.addEventListener('mouseup', () => {
                if (isDragging) {
                    isDragging = false;
                    pipPlayer.style.cursor = 'grab'; // Reset cursor style
                    document.removeEventListener('mousemove', onMouseMove);
                }
            });

            // Function to handle the mouse movement
            const onMouseMove = (e) => {
                if (!isDragging) return;

                let x = e.clientX - offsetX;
                let y = e.clientY - offsetY;

                // Ensure the player stays within viewport bounds
                const playerWidth = pipPlayer.offsetWidth;
                const playerHeight = pipPlayer.offsetHeight;
                const maxX = window.innerWidth - playerWidth;
                const maxY = window.innerHeight - playerHeight;

                if (x < 0) x = 0;
                if (y < 0) y = 0;
                if (x > maxX) x = maxX;
                if (y > maxY) y = maxY;

                pipPlayer.style.left = `${x}px`;
                pipPlayer.style.top = `${y}px`;
            };
        }
    },
};
